import profileConfig from '../../core/profiles/profileConfig';
import { getAllHazards, getAllSafetyEvents, getDashboardOverallData, getDashboardSiteData } from '../../core/services/minecept/mineceptServer';
import mineceptServer from '../../core/services/minecept/mineceptServer';
import { Falling } from '../../definition/Enums';
import { IMinceptSite, IMineceptVideoEvent, IMineceptMultiSite, IMineceptStatus, DashboardPeriods, DASHBOARD_ALL_SITES_OPTION, IMineceptDashboardData } from '../reducers/mining/IMiningReducerState';
import olMapService from '../../core/services/ol-map.service';
import mineceptVectorLayerProvider from '../../core/services/layers/mineceptVectorLayerProvider';
import { MineceptLayers } from '../../definition/mineceptLayers';
import { getSites } from '../selectors/mining/mining.selectors';
import { store } from '../../index';
import { convertWgs84ToWebMercator } from '../../core/services/projections';
import { getHazardFilters } from '../selectors/mining/mining.selectors';
import {setMapToCenter} from "./map.actions";

export enum MiningActionTypes {
	FALLING = '[Mining] SET_FALLING',
	SET_TRUCK_STATUS = '[Mining] SET_TRUCK_STATUS',
	CLEAR_REVERSE_ALERTS = '[Mining] CLEAR_REVERSE_ALERTS',
	FLIP_ALERT_SWITCH = '[Mining] FLIP_ALERT_SWITCH',
	SET_OBSTACLE_ALERTS = '[Mining] SET_OBSTACLE_ALERTS',
	SET_WARNING_SECTIONS = '[Mining] SET_WARNING_SECTIONS',
	CLOSE_TOOLTIP = '[Mining] CLOSE_TOOLTIP',
	SET_TOOLTIP = '[Mining] SET_TOOLTIP',
	SET_VEHICLE_TOOLTIP = '[Mining] SET_VEHICLE_TOOLTIP',
	SET_MINECEPT_STATUS = '[Mining] SET_MINECEPT_STATUS',
	TOGGLE_LAYER_DISPLAY = '[Mining] TOGGLE_LAYER_DISPLAY',
	SET_FORWARD_VIEW = '[Mining] SET_FORWARD_VIEW',
	SET_SAFETY_EVENTS = '[Mining] SET_SAFETY_EVENTS',
	ADD_SAFETY_EVENT = '[Mining] ADD_SAFETY_EVENT',
	UPDATE_SAFETY_EVENT = '[Mining] UPDATE_SAFETY_EVENT',
	SET_HAZARDS = '[Mining] SET_HAZARDS',
	ADD_HAZARDS = '[Mining] ADD_HAZARDS',
	REMOVE_HAZRADS = '[Mining] REMOVE_HAZARDS',
	RESTORE_HAZRADS = '[Mining] RESTORE_HAZRADS',
	UPDATE_HAZARD = '[Mining] UPDATE_HAZARD',
	CLEAR_HAZARDS = '[Mining] CLEAR_HAZARDS',
	REMOVE_SAFETY_EVENTS = '[Mining] REMOVE_SAFETY_EVENTS',
	RESTORE_SAFETY_EVENTS = '[Mining] RESTORE_SAFETY_EVENTS',
	CLEAR_SAFETY_EVENTS = '[Mining] CLEAR_SAFETY_EVENTS',
	SET_NEW_SAFETY_EVENT_FLAG = '[Mining] SET_NEW_SAFETY_EVENT_FLAG',
	SET_NEW_HAZARD_FLAG = '[Mining] SET_NEW_HAZARD_FLAG',
	SELECT = '[Mining] SELECT',
	SET_HAZARD_SORT = '[Mining] SET_HAZARD_SORT',
	SET_SAFETY_EVENT_SORT = '[Mining] SET_SAFETY_EVENT_SORT',
	SET_HAZARD_FILTERS = '[Mining] SET_HAZARD_FILTERS',
	SET_EVENT_FILTERS = '[Mining] SET_EVENT_FILTERS',
	RESET_HAZARD_FILTERS = '[Mining] RESET_HAZARD_FILTERS',
	RESET_EVENT_FILTERS = '[Mining] RESET_EVENT_FILTERS',
	MULTI_SELECT = '[Mining] MULTI_SELECT',
	MULTI_UNSELECT = '[Mining] MULTI_UNSELECT',
	MULTI_SELECT_ALL_HAZARDS = '[Mining] MULTI_SELECT_ALL_HAZARDS',
	MULTI_SELECT_ALL_SAFETY_EVENT = '[Mining] MULTI_SELECT_ALL_SAFETY_EVENT',
	MULTI_SELECT_IN_BBOX = '[Mining] MULTI_SELECT_IN_BBOX',
	MULTI_UNSELECT_ALL_HAZARDS = '[Mining] MULTI_UNSELECT_ALL_HAZARDS',
	MULTI_UNSELECT_ALL_SAFETY_EVENT = '[Mining] MULTI_UNSELECT_ALL_SAFETY_EVENT',
	CLEAR_MULTI_SELECT = '[Mining] CLEAR_MULTI_SELECT',
	SET_PLAYED_EVENT = '[Mining] SET_PLAYED_EVENT',
	SET_SITES = '[Mining] SET_SITES',
	SET_SELECTED_SITE = '[Mining] SET_SELECTED_SITE',
	SET_DASHBOARD_IS_LOADING_AND_ERROR = '[Dashboard] SET_DASHBOARD_IS_LOADING_AND_ERROR',
	SET_DASHBOARD_DATA = '[Dashboard] SET_DASHBOARD_DATA',
	RESET_DASHBOARD_DATA = '[Dashboard] RESET_DASHBOARD_DATA',
	FETCH_DASHBOARD_DATA = '[Dashboard] FETCH_DASHBOARD_DATA'
}

export const multiSelectInBbox = (payload: Array<number>) => ({
	type: MiningActionTypes.MULTI_SELECT_IN_BBOX,
	payload
});

export const multiSelect = (payload: string) => ({
	type: MiningActionTypes.MULTI_SELECT,
	payload
});

export const multiUnselect = (payload: string) => ({
	type: MiningActionTypes.MULTI_UNSELECT,
	payload
});

export const clearMultiSelect = () => ({
	type: MiningActionTypes.CLEAR_MULTI_SELECT
});

export const multiSelectAllHazards = (payload) => ({
	type: MiningActionTypes.MULTI_SELECT_ALL_HAZARDS,
	payload
});

export const multiSelectAllSafetyEvents = (payload) => ({
	type: MiningActionTypes.MULTI_SELECT_ALL_SAFETY_EVENT,
	payload
});

export const multiUnselectAllHazards = () => ({
	type: MiningActionTypes.MULTI_UNSELECT_ALL_HAZARDS
});

export const multiUnselectAllSafetyEvents = () => ({
	type: MiningActionTypes.MULTI_UNSELECT_ALL_SAFETY_EVENT
});

export const setIsFalling = (payload: Falling) => ({
	type: MiningActionTypes.FALLING,
	payload
});

export const setForwardView = () => ({
	type: MiningActionTypes.SET_FORWARD_VIEW,
});

export const setTruckStatus = (data) => {
	const payload = {
		isBermVisible: data.isBermVisible,
		isGoingReverse: profileConfig().includeSafeUnloadingScreen ? data.isGoingReverse : false,
		reverseData:data.reverseData,
	}

	return{
		type: MiningActionTypes.SET_TRUCK_STATUS,
		payload
	}
};

export const clearReverseAlerts = () => ({
	type: MiningActionTypes.CLEAR_REVERSE_ALERTS
});

export const flipAlertSwitch = (payload) => ({
	type: MiningActionTypes.FLIP_ALERT_SWITCH,
	payload
});

export const setObstacleAlerts = (payload) => ({
	type: MiningActionTypes.SET_OBSTACLE_ALERTS,
	payload
});

export const setWarningSectors = (payload: [0,0,0,0,0,0,0,0,0,0,0,0]) => ({
	type: MiningActionTypes.SET_WARNING_SECTIONS,
	payload
});

export const closeTooltip = () => ({
	type: MiningActionTypes.CLOSE_TOOLTIP,
});

export const setTooltip = (payload) => ({
	type: MiningActionTypes.SET_TOOLTIP,
	payload
});

export const setVehicleTooltip = (payload?:{id: string, lastSeen:number}) => ({
	type: MiningActionTypes.SET_VEHICLE_TOOLTIP,
	payload
});

export const setMineceptStatus = (payload: IMineceptStatus) => ({
	type: MiningActionTypes.SET_MINECEPT_STATUS,
	payload
});

export const setSafetyEvents = (payload) =>({
	type: MiningActionTypes.SET_SAFETY_EVENTS,
	payload
});

export const setHazards = (payload) =>({
	type: MiningActionTypes.SET_HAZARDS,
	payload
});

export const addHazards = (payload) =>({
	type: MiningActionTypes.ADD_HAZARDS,
	payload
});

export const updateHazard = (payload) =>({
	type: MiningActionTypes.UPDATE_HAZARD,
	payload
});

export const clearHazards = () =>({
	type: MiningActionTypes.CLEAR_HAZARDS
});

export const removeHazards = (payload) =>({
	type: MiningActionTypes.REMOVE_HAZRADS,
	payload
});

export const restoreHazards = (payload) =>({
	type: MiningActionTypes.RESTORE_HAZRADS,
	payload
});

export const addSafetyEvent = (payload) =>({
	type: MiningActionTypes.ADD_SAFETY_EVENT,
	payload
});

export const removeSafetyEvents = (payload) =>({
	type: MiningActionTypes.REMOVE_SAFETY_EVENTS,
	payload
});

export const restoreSafetyEvents = (payload) =>({
	type: MiningActionTypes.RESTORE_SAFETY_EVENTS,
	payload
});

export const updateSafetyEvent = (payload) =>({
	type: MiningActionTypes.UPDATE_SAFETY_EVENT,
	payload
});

export const clearSafetyEvents = () =>({
	type: MiningActionTypes.CLEAR_SAFETY_EVENTS
});

export const setNewSafetyEventFlag = (payload) =>({
	type: MiningActionTypes.SET_NEW_SAFETY_EVENT_FLAG,
	payload
});

export const setNewHazardFlag = (payload) =>({
	type: MiningActionTypes.SET_NEW_HAZARD_FLAG,
	payload
});

export const setSafetyEventSort = (payload) =>({
	type: MiningActionTypes.SET_SAFETY_EVENT_SORT,
	payload
});

export const setHazardSort = (payload) =>({
	type: MiningActionTypes.SET_HAZARD_SORT,
	payload
});

export const setHazardFilters = (payload) => async (dispatch) => {
	const prevArchive = getHazardFilters(store.getState()).archive
	dispatch({
		type: MiningActionTypes.SET_HAZARD_FILTERS,
		payload
	});

	// Default retreived hazards are not-archived, if filter change get all hazards
	if (prevArchive !== payload.archive && payload.archive) {
		try {
			const response = await getAllHazards();
			const hazards = response && response.data;
			if (hazards && hazards.length) {
				dispatch(setHazards(hazards));
			}
		} catch (e) {
			console.error('Failed to get all hazards', e);
		}
	}
};

export const setEventFilters = (payload) => async (dispatch) => {
	const prevArchive = getHazardFilters(store.getState()).archive
	dispatch({
		type: MiningActionTypes.SET_EVENT_FILTERS,
		payload
	});

	// Default retreived safety events are not-archived, if filter change get all safety events
	if (prevArchive !== payload.archive && payload.archive) {
		try {
			const response = await getAllSafetyEvents();
			const hazards = response && response.data;
			if (hazards && hazards.length) {
				dispatch(setSafetyEvents(hazards));
			}
		} catch (e) {
			console.error('Failed to get all safety events', e);
		}
	}
};

export const resetEventFilters = () => ( {type: MiningActionTypes.RESET_EVENT_FILTERS} );
export const resetHazardFilters = () => ( {type: MiningActionTypes.RESET_HAZARD_FILTERS} );

export const selectFeature = (payload:string|null = null) =>({
	type: MiningActionTypes.SELECT,
	payload
});

export const _closeTooltip = () => (dispatch) => {
	dispatch({
		type: MiningActionTypes.CLOSE_TOOLTIP
	});
};

export const toggleLayerDisplay = (layerId: string) => (dispatch) => {
	dispatch({
		type: MiningActionTypes.TOGGLE_LAYER_DISPLAY,
		payload: {
			layerId,
		}
	});
};

export const archiveMultiHazards = (ids) => async (dispatch) => {
	dispatch(multiUnselectAllHazards());
	await mineceptServer.removeHazards(ids);
};

export const archiveMultiSafetyEvents = (ids) => async (dispatch) => {
	dispatch(multiUnselectAllSafetyEvents());
	await mineceptServer.removeSafetyEvents(ids);
};

export const restoreMultiHazards = (ids) => async (dispatch) => {
	await mineceptServer.restoreHazards(ids);
	dispatch(multiUnselectAllHazards());
};

export const restoreMultiSafetyEvents = (ids) => async (dispatch) => {
	await mineceptServer.restoreSafetyEvents(ids);
	dispatch(multiUnselectAllSafetyEvents());
};

export const setEventPlayed = (payload?: IMineceptVideoEvent) => ({
	type: MiningActionTypes.SET_PLAYED_EVENT,
	payload
});

const clearMap = () => {
	// TODO refactor so map is connect correctly to store
	olMapService.clearSelection();
	olMapService.multiSelectFeatures([]);
	mineceptVectorLayerProvider.clearLayerData(MineceptLayers.vehicles);
}

const setMapView = (site: string, sites?: IMinceptSite[]) => {
	const latestSites: IMinceptSite[] = sites !== undefined ? sites : getSites(store.getState());
	const siteData = latestSites.find(x => x.id === site);
	if (siteData && siteData.center) {
		const position = convertWgs84ToWebMercator(siteData.center);
		olMapService.setCenter(position);
		if (siteData.zoom) {
			olMapService.setZoom(siteData.zoom);
			if(!olMapService.map) return;
			store.dispatch(setMapToCenter({center: position, resolution: olMapService.map.getView().getResolution()}));
		}
	}
}

export const updateUrlWithSite = (site: string, page: string | undefined = undefined, isChangePage = true) => {
	const defaultPage = isChangePage ? '/': '';
	let url: string | undefined = page || defaultPage;
	if (site) {
		url += `?site=${site}`;
	}
	if (!url) {
		url = undefined;
	}
	window.history.replaceState(null, '', url);
}

const setSite = (site: string, sites?: IMinceptSite[]) => {
	clearMap();
	setMapView(site, sites);
	updateUrlWithSite(site, undefined, false);
}

export const setSites = (payload: IMineceptMultiSite) => { 
	setSite(payload.selectedSite, payload.sites);
	return {
		type: MiningActionTypes.SET_SITES,
		payload
	}
};

export const setSelectedSite = (payload: string) => {
	setSite(payload, undefined);
	return {
		type: MiningActionTypes.SET_SELECTED_SITE,
		payload
	};
};

export interface SetDashboardDataPayload {
	site: string;
	periodDays: number;
	data: IMineceptDashboardData
}

export const setDashboardData = (payload: SetDashboardDataPayload) => ({
	type: MiningActionTypes.SET_DASHBOARD_DATA,
	payload
});

export const resetDashboardData = () => ({
	type: MiningActionTypes.RESET_DASHBOARD_DATA,
})

export interface IDashboardLoadingAnErrorPayload {
	isLoading: boolean;
	isError: boolean;
}

export const setDashboardIsLoadingAndError = (payload: IDashboardLoadingAnErrorPayload) => ({
	type: MiningActionTypes.SET_DASHBOARD_IS_LOADING_AND_ERROR,
	payload
});

export const fetchDashboardData = (site: string, periodDays: number) => async (dispatch) => {
	dispatch(setDashboardIsLoadingAndError({ isLoading: true, isError: false }));
	let result: any = undefined;
	if (site === DASHBOARD_ALL_SITES_OPTION) {
		result = await getDashboardOverallData(periodDays);
	} else {
		result = await getDashboardSiteData(site, periodDays);
	}

	if (result) {
		const data = {
			site,
			periodDays,
			data: {
				range: { start: result.range[0], end: result.range[1] },
				buckets: result.buckets.map(x => ({ start: x[0], end: x[1] })),
				graphs: result.graphs
			}
		}
		dispatch(setDashboardData(data));
	}
	
	const isError = !Boolean(result);
	dispatch(setDashboardIsLoadingAndError({ isLoading: false, isError }));
};
