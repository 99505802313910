import classes from "./StatusAlert.module.css";
import {
    getMineceptStatus_label,
    getMineceptStatus_status, getMineceptStatus_text
} from "../../../store/selectors/mining/mining.selectors";
import React, {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {confirmAlert} from "react-confirm-alert"; // Import
import connectingIcon from '../../../assets/images/connecting.svg'
import {isVertical} from "../../../services/obstacles";

const StatusAlert = () => {
    const status = useSelector(getMineceptStatus_status);
    const title = useSelector(getMineceptStatus_label);
    const text = useSelector(getMineceptStatus_text);
    const onCloseFunc = useRef(() => {
    });
    useEffect(() => {
        const isHasLabel = (title !== undefined) && (title !== '');
        const isHasText = (text !== undefined) && (text !== '');
        const isHideView = (!isHasLabel && !isHasText) && status !== 3;
        if (isHideView) {
            onCloseFunc.current && onCloseFunc.current();
            return;
        }
        confirmDialog();
    }, [status, title, text]);
    const fillContent = () => {
        if (status === 3) {
            return <div className={isVertical ? classes.ConnectingVertical : classes.Connecting}>
                <div className={isVertical ? classes.ConnectingContentVertical : classes.ConnectingContent}>
                    <img className={classes.LoadingIcon} src={connectingIcon} alt='connecting icon'/>
                    <p className={classes.ConnectingTitle}>Connecting...</p>
                </div>
            </div>
        }
        if (text || title) {
            return <div className={isVertical ? classes.RectangleVertical : classes.Rectangle}>
                {title && <p className={isVertical ? classes.verticalTitle : classes.title}>{title}</p>}
                {text && <p className={isVertical ? classes.verticalText : classes.text}>{text}</p>}
            </div>;
        }
    }

    const confirmDialog = () => {
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({onClose}) => {
                onCloseFunc.current = onClose;
                return (
                    <div className={classes.backdrop}>
                        {fillContent()}
                    </div>

                );
            },
        });
    };

    return <div className="container"></div>;

};
export default StatusAlert;
