import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import './Menu.scss';
import { setActiveMenu, setCurrentPage } from '../../../store/actions/core.actions';
import SensorPanel from '../../sensors/sensors-panel/SensorPanel';
import SourceProviderPanel from '../../map/source-provider-panel/SourceProviderPanel';
import AdasLayersProviderPanel from '../../adas-layers/adas-layers-provider-panel/AdasLayersProviderPanel';
import mapsIcon from '../../../assets/images/menuIcons/maps.svg';
import traceIcon from '../../../assets/images/menuIcons/trace.svg';
import layersIcon from '../../../assets/images/menuIcons/layers.svg';
import eventsIcon from '../../../assets/images/menuIcons/events.svg';
import eventsAlertIcon from '../../../assets/images/menuIcons/eventsRedDot.svg';
import hazardsIcon from '../../../assets/images/menuIcons/hazards.svg';
import hazardsAlertIcon from '../../../assets/images/menuIcons/hazardsRedDot.svg';
import dashboardIcon from '../../../assets/images/menuIcons/dashboard.svg';
import EditLayerModePanel from '../../adas-layers/edit-layer-mode-panel/EditLayerModePanel';
import { DrawModes } from '../../../definition/draw-modes';
import { AdasLayerStylesEnum } from '../../../definition/adas-layer-styles-enum';
import { editAdasLayer } from '../../adas-layers/actions/adas-layers.actions';
import EditFeatureModePanel from '../../adas-layers/edit-feature-mode-panel/EditFeatureModePanel';
import CreateNewLayerPanel from '../../adas-layers/create-new-layer-panel/CreateNewLayerPanel';
import profileConfig from '../../../core/profiles/profileConfig';
import SideTable from '../../sideTable/SideTable';
import safetyEventsMenuData from './menus/safetyEventsMenuData';
import hazardsMenuData from './menus/hazardsMenuData';
import { getActiveMenu } from '../../../store/selectors/core.selector';
import { getNewHazardFlag, getNewSafetyEventFlag } from '../../../store/selectors/mining/mining.selectors';
import { getAdasEditLayer, getAdasEditLayerId } from '../../../store/selectors/adas.selector';
import Logo from './logo/logo';
import { config } from '../../../services/config';
import {resetDashboardData, setEventPlayed} from '../../../store/actions/mining.actions';
import olMapService from "../../../core/services/ol-map.service";
import {setMapToCenter} from "../../../store/actions/map.actions";

const Menu = () => {
	const dispatch = useDispatch();
	const activeMenu = useSelector(getActiveMenu);
	const adasEditLayer = useSelector(getAdasEditLayer);
	const layerId = useSelector(getAdasEditLayerId );
	const safetyEventAlert = useSelector(getNewSafetyEventFlag);
	const newHazardAlert= useSelector(getNewHazardFlag);

	const getClassName = (menuItem: string) => {
		return classNames({
			'selected': activeMenu === menuItem,
			'menu-item': true,
		});
	}

	const resetEditAdasLayer = () => {
		if (layerId) {
			dispatch(editAdasLayer({
				...adasEditLayer,
				style: AdasLayerStylesEnum.DEFAULT,
				drawMode: DrawModes.NONE,
			}));
			dispatch(editAdasLayer({ ...adasEditLayer, id: '' }));
		}
	}

	const onSelect = (selectedMenu: string) => {
		if (selectedMenu !== activeMenu) {
			dispatch(setActiveMenu(selectedMenu));
		} else {
			dispatch(setActiveMenu(''));
		}
		resetEditAdasLayer();
	}

	const isShowTrace =() => {
		const isShowTraceOption =process.env.REACT_APP_SHOW_TRACE;
		return isShowTraceOption && isShowTraceOption.trim().toLowerCase() === 'true';
	}

	const isShowDashboard = () => {
		return config.isMultiSite;
	}

	const handleDashboardSelection = () => {
		dispatch(resetDashboardData())
		dispatch(setEventPlayed(undefined));//in case of moving to dashboard before video event stopped
		dispatch(setMapToCenter({center: (olMapService.map.getView().getCenter()), resolution: olMapService.map.getView().getResolution()})
		)
		dispatch(setCurrentPage({ page: 'dashboard', isSetUrl: true }));
	}

	return (
		<div className='menu'>

			<div className='panels'>
				<SensorPanel display={activeMenu === 'trace'} parentClass="panel-container menu-item"
							 menuClass='menu-item' />
				<SourceProviderPanel display={activeMenu === 'map-source'} parentClass="panel-container"
									 menuClass='menu-item' />
				<AdasLayersProviderPanel display={activeMenu === 'adas-layers'}
										 parentClass="panel-container" menuClass='menu-item' />
				<EditLayerModePanel display={activeMenu === 'edit-layer-mode'}
									parentClass="panel-container menu-item" menuClass='menu-item' />
				<CreateNewLayerPanel display={activeMenu === 'create-new-layer'}
									 parentClass="panel-container menu-item" menuClass='menu-item' />
				<EditFeatureModePanel display={activeMenu === 'edit-feature-mode'}
									  parentClass="panel-container menu-item" menuClass='menu-item' />
				<SideTable display={activeMenu === 'safety-event-log'}
						   close={() => {
							   dispatch(setActiveMenu(''));
						   }}
						   {...safetyEventsMenuData()}
				/>
				<SideTable display={activeMenu === 'hazard-log'}
						   close={() => {
							   dispatch(setActiveMenu(''));
						   }}
						   {...hazardsMenuData()}
				/>
			</div>

			<div className='menu-container'>

				<ul>
					<li className={getClassName('map-source')} onClick={() => onSelect('map-source')}>
						<img src={mapsIcon} />
					</li>

					<li className={getClassName('adas-layers')} onClick={() => onSelect('adas-layers')}>
						<img src={layersIcon} />
					</li>
					{
						profileConfig().showSafetEventList && <>
							<li className={getClassName('safety-event-log')}
								onClick={() => onSelect('safety-event-log')}>
								<img src={safetyEventAlert ? eventsAlertIcon : eventsIcon} />
							</li>
						</>
					}
					{
						profileConfig().showHazardList && <>
							<li className={getClassName('hazard-log')}
								onClick={() => onSelect('hazard-log')}>
								<img src={newHazardAlert ? hazardsAlertIcon : hazardsIcon}
									 width="62px" />
							</li>
						</>
					}
					{
						 isShowTrace() && profileConfig().showTraceBar &&
							<li className={getClassName('trace')} onClick={() => onSelect('trace')}>
								<img src={traceIcon}/>
							</li>
					}
					{
						isShowDashboard() && <li className={getClassName('dashboard')} onClick={handleDashboardSelection}>
							<img src={dashboardIcon}/>
						</li>
					}
				</ul>
				<Logo/>
			</div>

		</div>
	);
}

export default Menu;
