import { config } from '../../../services/config';

const obstacleMinSeverityForSound = parseInt(process.env.REACT_APP_OBSTACLE_MINIMUM_SEVERITY_FOR_SOUND as string);

const getImportance = (obstacle) => {
	const importanceMatrix = config.importanceMatrix;
	const typeImportance = importanceMatrix[obstacle.obstacleType.toString()];
	if(!typeImportance) return 0;
	return typeImportance[obstacle.severity] || 0;
}

export const getAlertStringFromObstacles = (obstacles) => {
	const soundList = config.soundList.sounds;
	const alert = ['obstacle', 0];
	if (!obstacles || !obstacles.length) {
		return alert;
	}
	const mostImportantObstacle = obstacles.reduce((prev, curr) => getImportance(curr) > getImportance(prev) ? curr : prev );
	if ( mostImportantObstacle.severity < obstacleMinSeverityForSound) alert;
	let alertString = `obstacle_type_${mostImportantObstacle.obstacleType}_severity_${mostImportantObstacle.severity}`;
	if (!soundList[alertString]) {
		const severity = mostImportantObstacle.severity;
		const fallbackAlerts = [`obstacle_severity_${severity}`, `obstacle_${severity}`, `obstacle${severity}`];
		alertString = fallbackAlerts.find(x => Boolean(soundList[x])) || 'obstacle';
	}
	return [alertString, getImportance(mostImportantObstacle)];
};
