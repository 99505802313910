import { Falling, SortBy } from '../../../definition/Enums';
import { layerList } from '../../../core/services/layers/mineceptVectorLayerProvider';
import { DashboardPeriods, DASHBOARD_ALL_SITES_OPTION, IMiningReducerState } from './IMiningReducerState';
import { getEffectiveSeverityKeys } from '../../../definition/severity';

const todayStart = new Date();
todayStart.setHours(0);
todayStart.setMinutes(0);
const todayEnd = new Date();
todayEnd.setHours(23);
todayEnd.setMinutes(59);

const miningReducerInitState:IMiningReducerState = {
	mineceptStatus: { status: 3, label: '', text: '' },
	isFalling: Falling.Ready,
	isGoingReverse: false,
	isBermVisible: false,
	reverseData: {
		SpeedAlert: 0,
		ApproachAngleAlert: 0,
		MidAxleHeightAlert: 0,
		SpeedKMH: 0,
		DistanceToBermCm: 0,
		ApproachAngle: 0,
		BermHeightCM: 0,
	},
	alertSwitches: {
		speedAlert: true,
		approachAngleAlert: true,
		midAxleHeightAlert: true,
	},
	obstacles: [],
	tooltips:{
		obstacle: {
			isOpen: false,
			severity: 0,
			obstacleType: 0,
			obstacleDescription: '',
			position: [0, 0],
		}
	},
	warningSectors: new Array(12).fill(1), //for testing put [0,0,1,2,3,4,0,0,0,0,0,0]
	mineceptLayers: {},
	selected: null,
	multiSelect:[],
	safetyEvents: [],
	hazards: [],
	flags: {
		newSafetyEvent: false,
		newHazard: false,
	},
	controls: {
		safetyEvents: {
			sortBy: SortBy.time,
			filters: {
				type: [...Array(100).keys()],
				severity: getEffectiveSeverityKeys(),
				filterStart: false,
				startTime: todayStart,
				filterEnd: false,
				endTime: todayEnd,
				archive : false,
				filterVehicles: false,
				vehicles: []
			}
		},
		hazards: {
			sortBy: SortBy.time,
			filters: {
				type: [...Array(100).keys()],
				severity: getEffectiveSeverityKeys(),
				filterStart: false,
				startTime: todayStart,
				filterEnd: false,
				endTime: todayEnd,
				archive : false,
				filterVehicles: false,
				vehicles: []
			}
		},
	},
	playedEvent: undefined,
	multiSite: {
		sites: [],
		selectedSite: ''
	},
	dashboard: {
		site: DASHBOARD_ALL_SITES_OPTION,
		period: DashboardPeriods.Day,
		isLoading: false,
		isError: false,
		data: undefined,
	}
};
Object.keys(layerList).forEach(layerId => miningReducerInitState.mineceptLayers[layerId] = true);

export default miningReducerInitState;
