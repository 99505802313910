import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import proj4 from 'proj4';
import { clearRoute } from '../../../core/actions/route.actions';
import {
	clearRotation,
	toggleAudioState,
	toggleFollowRoute,
	toggleMapStaticState
} from '../../../store/actions/map.actions';
import { isAllSensorsOff } from '../../sensors/selectors/sensor.selectors';
import volumeOnIcon from '../../../assets/images/unmute.svg';
import volumeOffIcon from '../../../assets/images/mute.svg';
import './MapControl.scss';
import olMapService from '../../../core/services/ol-map.service';
import { getSensorLastPosition } from '../../../store/selectors/route.selectors';
import Emitter from '../../../emitter';
import { config } from '../../../services/config';
import { MenuItem, Radio, Select } from '@material-ui/core';
import { IMinceptSite } from '../../../store/reducers/mining/IMiningReducerState';
import { setSelectedSite } from '../../../store/actions/mining.actions';
import videoContext from "../../../store/context/videoContext";

export interface IMapControlProps {
	mapRotation: number;
	mapStaticState: boolean;
	mapFollowRouteState: boolean;
	audioStateOn: boolean;
	currentAudioIcon: any;
	sites: IMinceptSite[];
	selectedSite: string;

	clearRoute: () => void;
	toggleAudioState: () => void;
	toggleMapStaticState: () => void;
	toggleFollowRoute: () => void;
	clearRotation: () => void;
	setSelectedSite: (site: string) => void;
}

class MapControl extends Component<IMapControlProps, any> {
	static contextType = videoContext;
	public videoCtx = this.context;
	getPointNorthStyle(): string {
		return `rotate(${this.props.mapRotation}rad)`;
	}

	clickOnTracingButton() {
		if (isAllSensorsOff()) {
			this.centerToSensor('sl');
		} else {
			this.props.toggleMapStaticState();
		}
	}

	clickOnStickToNorthButton() {
		if (this.props.mapStaticState) {
			this.props.clearRotation();
		} else {
			this.props.toggleFollowRoute();
			Emitter.emit('set map.followRoute', !this.props.mapFollowRouteState );
		}
	}

	tracingClassName (){
		return classNames({
			"round": true,
			"follow-route": true,
			"is-active": !this.props.mapStaticState
		});
	}

	pointNorthClassName () {
		return classNames({
			"round": true,
			"north": true,
			"is-active": this.props.mapFollowRouteState
		});
	}

	centerToSensor = (sensorType) => {
		const point = getSensorLastPosition(sensorType);

		if (point) {
			olMapService.setCenter(proj4('EPSG:3857', point));
		}

		return {
			type: ''
		};
	};

	zoomIn = () => {
		const zoom = olMapService.map.getView().getZoom();
		olMapService.map.getView().setZoom(zoom + 1);
	};

	zoomOut = () => {
		const zoom = olMapService.map.getView().getZoom();
		olMapService.map.getView().setZoom(zoom - 1);
	};

	mapControlWrapperClassName = () => {
		const multiSiteClassName = config.isMultiSite ? 'map-control-wrapper-multi-site' : '';
		const className = ['map-control-wrapper', multiSiteClassName].filter(x => Boolean(x)).join(' ');
		return className;
	}

	handleSelectSite = (event: React.ChangeEvent<{ value: unknown }>) => {
		this.props.setSelectedSite(event.target.value as string);
	};
	toggleAudio = () =>{
		if(this.videoCtx) {
			//only if there is a video manager can control the sound
			//For video, toggling audio is by changing play status.
			//Status of true means that audio will be heard and false will be silent
			this.videoCtx.isPlayedChange(!this.videoCtx.isPlayed);
		}
		this.props.toggleAudioState();
	};
	render() {
	 this.videoCtx = this.context;

		return (
			<div>
				{ config.isMultiSite && <div className='sites-selector-container'>
					<Select value={this.props.selectedSite} onChange={this.handleSelectSite}
						renderValue={(selected) => (<div className='site-label site-selected-label'>
							{(this.props.sites.find(x => x.id === selected) || {}).name}
						</div>)}>
						{this.props.sites.map(site => (<MenuItem key={site.id} value={site.id}>
							<Radio size='small' checked={site.id === this.props.selectedSite} />
							<div className='site-label'>{site.name}</div>
						</MenuItem>))}
					</Select>
				</div> }
				<div className={this.mapControlWrapperClassName()}>
					{!config.general.volume.disableVolumeControl && <div className='round small-round clear-route' onClick={ this.toggleAudio}>
						<img src={this.props.currentAudioIcon} className='icon-audio'/>
					</div>}
					<div
						className={this.pointNorthClassName()}
						style={{ transform: this.getPointNorthStyle() }}
						onClick={() => this.clickOnStickToNorthButton()}
					>
						<span className='icon-align-north gradient-text' />
					</div>
					<div className={this.tracingClassName()} onClick={() => this.clickOnTracingButton()}>
						<span className='icon-re-center gradient-text' />
					</div>
					<div className='zoom up' onClick={() => this.zoomIn()}>
						<span className='gradient-text text-button-zoom'>+</span>
					</div>
					<div className='zoom down' onClick={() => this.zoomOut()}>
						<span className='gradient-text text-button-zoom'>-</span>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	mapRotation: state.map.mapRotation,
	mapStaticState: state.map.staticState,
	mapFollowRouteState: state.map.followRoute,
	audioStateOn: state.map.audioStateOn,
	currentAudioIcon: state.map.audioStateOn ? volumeOnIcon : volumeOffIcon,
	sites: state.mining.multiSite.sites,
	selectedSite: state.mining.multiSite.selectedSite
});

const mapDispatchToProps = {
	clearRoute,
	toggleAudioState,
	toggleMapStaticState,
	toggleFollowRoute,
	clearRotation,
	setSelectedSite
};

export default connect(mapStateToProps, mapDispatchToProps)(MapControl);
