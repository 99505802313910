import React from 'react';
import { Bar } from 'react-chartjs-2';
import { config } from '../../../../services/config';
import { IMineceptDashboardOverallEventByType } from '../../../../store/reducers/mining/IMiningReducerState';

interface IProps {
	graph: IMineceptDashboardOverallEventByType;
}

const getEventTypeLabels = (graphEventData: { [key: string]: number }) =>
	Object.keys(graphEventData).map(
		type => config.hazardAndSafetyLabels.safeties[type] || 'Unknown'
	);

const getEventTypeColors = (graphEventTypes: number[]) =>
	graphEventTypes.map(
		type => config.hazardAndSafetyColors.safeties[type] || config.general.dashboard.defaultColor
	);

const Chart = ({ graph }: IProps) => (
	<Bar
		options={{
			plugins: {
				title: {
					display: true,
					text: 'Safety event types',
				},
				datalabels: {
					display:
						config.general.dashboard.showDataLabels
							.overallEventsByType,
					color: 'black',
				},
				legend: {
					display: false,
				},
			},
			indexAxis: 'y',
			maintainAspectRatio: false,
			scales: {
				y: { beginAtZero: true },
			},
		}}
		data={{
			labels: getEventTypeLabels(graph.data),
			datasets: [
				{
					data: Object.values(graph.data),
					backgroundColor: getEventTypeColors(graph.eventTypes),
					borderColor: getEventTypeColors(graph.eventTypes),
					borderWidth: 1,
				},
			],
		}}
	/>
);

export default Chart;
